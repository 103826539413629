import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function RoofType({handleClick,handleRoofCoverType,roofCoverType}) {
  return (
    <div className='roofType'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Welche Art von Dach haben Sie?</h2>
                        <p>Wählen Sie aus, wie steil Ihr Dach gebaut ist</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <div className={roofCoverType === 'ClayTiles'? 'theme-box active' : 'theme-box'} onClick={()=>handleRoofCoverType('ClayTiles')}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/tiledroof.svg' />
                                    </div>
                                    <h5>Ziegeldach</h5>
                                </div>
                            </div>
                            <div className={roofCoverType === 'TrapezoidalSheetMetal'? 'theme-box active' : 'theme-box'} onClick={()=>handleRoofCoverType('TrapezoidalSheetMetal')}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/tinroof.svg' />
                                    </div>
                                    <h5>Blechdach</h5>
                                </div>
                            </div>
                            <div className={roofCoverType === 'Others'? 'theme-box active' : 'theme-box'} onClick={()=>handleRoofCoverType('Others')}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/otheroof.svg' />
                                    </div>
                                    <h5>Sonstiges</h5>
                                </div>
                            </div>
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('roof-angle')}><GrLinkPrevious /> Zurück </div>
                            <div className={roofCoverType === null? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('kwp')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
