import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function EmailVerification({handleClick,handleEmail,email,gatherData}) {
    console.log(gatherData());
    
  return (
    <div className='emailVerification'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Ihre kostenlose Kostenvorausberechnung wartet auf Sie</h2>
                        <p>Bitte geben Sie Ihre E-Mail-Adresse an, damit wir Ihnen die<br/> detaillierte Kostenvorausberechnung zusenden können.</p>
                        <br/>
                        <ul className='features'>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g clip-path="url(#clip0_16_14979)">
                                        <path d="M9.26733 2.12518C9.3813 2.40081 9.60006 2.61991 9.87553 2.73429L10.8414 3.13439C11.1171 3.24857 11.3361 3.46758 11.4503 3.74323C11.5645 4.01889 11.5645 4.32862 11.4503 4.60427L11.0505 5.5695C10.9362 5.84527 10.9361 6.15531 11.0508 6.43096L11.45 7.39589C11.5066 7.53242 11.5357 7.67877 11.5357 7.82657C11.5358 7.97436 11.5067 8.12072 11.4501 8.25726C11.3935 8.39381 11.3106 8.51788 11.2061 8.62237C11.1016 8.72686 10.9775 8.80973 10.8409 8.86625L9.87569 9.26606C9.60006 9.38003 9.38096 9.59879 9.26658 9.87426L8.86648 10.8402C8.7523 11.1158 8.53329 11.3348 8.25764 11.449C7.98198 11.5632 7.67226 11.5632 7.3966 11.449L6.43138 11.0492C6.15571 10.9353 5.8461 10.9355 5.57061 11.0499L4.60469 11.4494C4.32919 11.5633 4.01973 11.5632 3.7443 11.4491C3.46887 11.335 3.24998 11.1163 3.13572 10.8409L2.73551 9.87471C2.62154 9.59907 2.40277 9.37998 2.12731 9.26559L1.16139 8.8655C0.885857 8.75137 0.666914 8.5325 0.552691 8.257C0.438468 7.9815 0.438313 7.67192 0.552261 7.39631L0.952069 6.43109C1.06597 6.15542 1.06574 5.84581 0.951422 5.57031L0.552188 4.60371C0.495584 4.46718 0.466438 4.32083 0.466414 4.17303C0.466391 4.02524 0.49549 3.87888 0.552049 3.74234C0.608609 3.60579 0.691521 3.48172 0.796046 3.37723C0.900572 3.27274 1.02466 3.18987 1.16123 3.13335L2.12645 2.73354C2.40185 2.61967 2.62081 2.40118 2.73528 2.12604L3.13537 1.16012C3.24955 0.884465 3.46856 0.665457 3.74422 0.551276C4.01988 0.437095 4.3296 0.437095 4.60526 0.551276L5.57048 0.951085C5.84614 1.06498 6.15576 1.06475 6.43125 0.950438L7.39757 0.551896C7.67319 0.43778 7.98285 0.437803 8.25845 0.551961C8.53405 0.666119 8.75303 0.885065 8.86723 1.16065L9.26745 2.12685L9.26733 2.12518Z" fill="#0788F5"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34995 4.42718C8.43011 4.30127 8.45697 4.14867 8.42462 4.00296C8.39227 3.85724 8.30336 3.73035 8.17745 3.65018C8.05153 3.57002 7.89894 3.54316 7.75322 3.57551C7.6075 3.60786 7.48061 3.69677 7.40045 3.82268L5.1977 7.28393L4.1897 6.02393C4.09651 5.90737 3.96083 5.8326 3.81251 5.81608C3.66419 5.79955 3.51538 5.84262 3.39882 5.93581C3.28226 6.029 3.20749 6.16468 3.19096 6.313C3.17444 6.46131 3.21751 6.61012 3.3107 6.72668L4.8107 8.60168C4.8666 8.67165 4.9384 8.72726 5.02012 8.76388C5.10185 8.8005 5.19114 8.81708 5.28056 8.81224C5.36999 8.8074 5.45696 8.78128 5.53426 8.73604C5.61155 8.69081 5.67692 8.62778 5.72495 8.55218L8.34995 4.42718Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_14979">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Gewinnvergleich
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g clip-path="url(#clip0_16_14979)">
                                        <path d="M9.26733 2.12518C9.3813 2.40081 9.60006 2.61991 9.87553 2.73429L10.8414 3.13439C11.1171 3.24857 11.3361 3.46758 11.4503 3.74323C11.5645 4.01889 11.5645 4.32862 11.4503 4.60427L11.0505 5.5695C10.9362 5.84527 10.9361 6.15531 11.0508 6.43096L11.45 7.39589C11.5066 7.53242 11.5357 7.67877 11.5357 7.82657C11.5358 7.97436 11.5067 8.12072 11.4501 8.25726C11.3935 8.39381 11.3106 8.51788 11.2061 8.62237C11.1016 8.72686 10.9775 8.80973 10.8409 8.86625L9.87569 9.26606C9.60006 9.38003 9.38096 9.59879 9.26658 9.87426L8.86648 10.8402C8.7523 11.1158 8.53329 11.3348 8.25764 11.449C7.98198 11.5632 7.67226 11.5632 7.3966 11.449L6.43138 11.0492C6.15571 10.9353 5.8461 10.9355 5.57061 11.0499L4.60469 11.4494C4.32919 11.5633 4.01973 11.5632 3.7443 11.4491C3.46887 11.335 3.24998 11.1163 3.13572 10.8409L2.73551 9.87471C2.62154 9.59907 2.40277 9.37998 2.12731 9.26559L1.16139 8.8655C0.885857 8.75137 0.666914 8.5325 0.552691 8.257C0.438468 7.9815 0.438313 7.67192 0.552261 7.39631L0.952069 6.43109C1.06597 6.15542 1.06574 5.84581 0.951422 5.57031L0.552188 4.60371C0.495584 4.46718 0.466438 4.32083 0.466414 4.17303C0.466391 4.02524 0.49549 3.87888 0.552049 3.74234C0.608609 3.60579 0.691521 3.48172 0.796046 3.37723C0.900572 3.27274 1.02466 3.18987 1.16123 3.13335L2.12645 2.73354C2.40185 2.61967 2.62081 2.40118 2.73528 2.12604L3.13537 1.16012C3.24955 0.884465 3.46856 0.665457 3.74422 0.551276C4.01988 0.437095 4.3296 0.437095 4.60526 0.551276L5.57048 0.951085C5.84614 1.06498 6.15576 1.06475 6.43125 0.950438L7.39757 0.551896C7.67319 0.43778 7.98285 0.437803 8.25845 0.551961C8.53405 0.666119 8.75303 0.885065 8.86723 1.16065L9.26745 2.12685L9.26733 2.12518Z" fill="#0788F5"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34995 4.42718C8.43011 4.30127 8.45697 4.14867 8.42462 4.00296C8.39227 3.85724 8.30336 3.73035 8.17745 3.65018C8.05153 3.57002 7.89894 3.54316 7.75322 3.57551C7.6075 3.60786 7.48061 3.69677 7.40045 3.82268L5.1977 7.28393L4.1897 6.02393C4.09651 5.90737 3.96083 5.8326 3.81251 5.81608C3.66419 5.79955 3.51538 5.84262 3.39882 5.93581C3.28226 6.029 3.20749 6.16468 3.19096 6.313C3.17444 6.46131 3.21751 6.61012 3.3107 6.72668L4.8107 8.60168C4.8666 8.67165 4.9384 8.72726 5.02012 8.76388C5.10185 8.8005 5.19114 8.81708 5.28056 8.81224C5.36999 8.8074 5.45696 8.78128 5.53426 8.73604C5.61155 8.69081 5.67692 8.62778 5.72495 8.55218L8.34995 4.42718Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_14979">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Ökologie
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g clip-path="url(#clip0_16_14979)">
                                        <path d="M9.26733 2.12518C9.3813 2.40081 9.60006 2.61991 9.87553 2.73429L10.8414 3.13439C11.1171 3.24857 11.3361 3.46758 11.4503 3.74323C11.5645 4.01889 11.5645 4.32862 11.4503 4.60427L11.0505 5.5695C10.9362 5.84527 10.9361 6.15531 11.0508 6.43096L11.45 7.39589C11.5066 7.53242 11.5357 7.67877 11.5357 7.82657C11.5358 7.97436 11.5067 8.12072 11.4501 8.25726C11.3935 8.39381 11.3106 8.51788 11.2061 8.62237C11.1016 8.72686 10.9775 8.80973 10.8409 8.86625L9.87569 9.26606C9.60006 9.38003 9.38096 9.59879 9.26658 9.87426L8.86648 10.8402C8.7523 11.1158 8.53329 11.3348 8.25764 11.449C7.98198 11.5632 7.67226 11.5632 7.3966 11.449L6.43138 11.0492C6.15571 10.9353 5.8461 10.9355 5.57061 11.0499L4.60469 11.4494C4.32919 11.5633 4.01973 11.5632 3.7443 11.4491C3.46887 11.335 3.24998 11.1163 3.13572 10.8409L2.73551 9.87471C2.62154 9.59907 2.40277 9.37998 2.12731 9.26559L1.16139 8.8655C0.885857 8.75137 0.666914 8.5325 0.552691 8.257C0.438468 7.9815 0.438313 7.67192 0.552261 7.39631L0.952069 6.43109C1.06597 6.15542 1.06574 5.84581 0.951422 5.57031L0.552188 4.60371C0.495584 4.46718 0.466438 4.32083 0.466414 4.17303C0.466391 4.02524 0.49549 3.87888 0.552049 3.74234C0.608609 3.60579 0.691521 3.48172 0.796046 3.37723C0.900572 3.27274 1.02466 3.18987 1.16123 3.13335L2.12645 2.73354C2.40185 2.61967 2.62081 2.40118 2.73528 2.12604L3.13537 1.16012C3.24955 0.884465 3.46856 0.665457 3.74422 0.551276C4.01988 0.437095 4.3296 0.437095 4.60526 0.551276L5.57048 0.951085C5.84614 1.06498 6.15576 1.06475 6.43125 0.950438L7.39757 0.551896C7.67319 0.43778 7.98285 0.437803 8.25845 0.551961C8.53405 0.666119 8.75303 0.885065 8.86723 1.16065L9.26745 2.12685L9.26733 2.12518Z" fill="#0788F5"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34995 4.42718C8.43011 4.30127 8.45697 4.14867 8.42462 4.00296C8.39227 3.85724 8.30336 3.73035 8.17745 3.65018C8.05153 3.57002 7.89894 3.54316 7.75322 3.57551C7.6075 3.60786 7.48061 3.69677 7.40045 3.82268L5.1977 7.28393L4.1897 6.02393C4.09651 5.90737 3.96083 5.8326 3.81251 5.81608C3.66419 5.79955 3.51538 5.84262 3.39882 5.93581C3.28226 6.029 3.20749 6.16468 3.19096 6.313C3.17444 6.46131 3.21751 6.61012 3.3107 6.72668L4.8107 8.60168C4.8666 8.67165 4.9384 8.72726 5.02012 8.76388C5.10185 8.8005 5.19114 8.81708 5.28056 8.81224C5.36999 8.8074 5.45696 8.78128 5.53426 8.73604C5.61155 8.69081 5.67692 8.62778 5.72495 8.55218L8.34995 4.42718Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_14979">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Detaillierte Liste aller Kosten
                            </li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <g clip-path="url(#clip0_16_14979)">
                                        <path d="M9.26733 2.12518C9.3813 2.40081 9.60006 2.61991 9.87553 2.73429L10.8414 3.13439C11.1171 3.24857 11.3361 3.46758 11.4503 3.74323C11.5645 4.01889 11.5645 4.32862 11.4503 4.60427L11.0505 5.5695C10.9362 5.84527 10.9361 6.15531 11.0508 6.43096L11.45 7.39589C11.5066 7.53242 11.5357 7.67877 11.5357 7.82657C11.5358 7.97436 11.5067 8.12072 11.4501 8.25726C11.3935 8.39381 11.3106 8.51788 11.2061 8.62237C11.1016 8.72686 10.9775 8.80973 10.8409 8.86625L9.87569 9.26606C9.60006 9.38003 9.38096 9.59879 9.26658 9.87426L8.86648 10.8402C8.7523 11.1158 8.53329 11.3348 8.25764 11.449C7.98198 11.5632 7.67226 11.5632 7.3966 11.449L6.43138 11.0492C6.15571 10.9353 5.8461 10.9355 5.57061 11.0499L4.60469 11.4494C4.32919 11.5633 4.01973 11.5632 3.7443 11.4491C3.46887 11.335 3.24998 11.1163 3.13572 10.8409L2.73551 9.87471C2.62154 9.59907 2.40277 9.37998 2.12731 9.26559L1.16139 8.8655C0.885857 8.75137 0.666914 8.5325 0.552691 8.257C0.438468 7.9815 0.438313 7.67192 0.552261 7.39631L0.952069 6.43109C1.06597 6.15542 1.06574 5.84581 0.951422 5.57031L0.552188 4.60371C0.495584 4.46718 0.466438 4.32083 0.466414 4.17303C0.466391 4.02524 0.49549 3.87888 0.552049 3.74234C0.608609 3.60579 0.691521 3.48172 0.796046 3.37723C0.900572 3.27274 1.02466 3.18987 1.16123 3.13335L2.12645 2.73354C2.40185 2.61967 2.62081 2.40118 2.73528 2.12604L3.13537 1.16012C3.24955 0.884465 3.46856 0.665457 3.74422 0.551276C4.01988 0.437095 4.3296 0.437095 4.60526 0.551276L5.57048 0.951085C5.84614 1.06498 6.15576 1.06475 6.43125 0.950438L7.39757 0.551896C7.67319 0.43778 7.98285 0.437803 8.25845 0.551961C8.53405 0.666119 8.75303 0.885065 8.86723 1.16065L9.26745 2.12685L9.26733 2.12518Z" fill="#0788F5"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.34995 4.42718C8.43011 4.30127 8.45697 4.14867 8.42462 4.00296C8.39227 3.85724 8.30336 3.73035 8.17745 3.65018C8.05153 3.57002 7.89894 3.54316 7.75322 3.57551C7.6075 3.60786 7.48061 3.69677 7.40045 3.82268L5.1977 7.28393L4.1897 6.02393C4.09651 5.90737 3.96083 5.8326 3.81251 5.81608C3.66419 5.79955 3.51538 5.84262 3.39882 5.93581C3.28226 6.029 3.20749 6.16468 3.19096 6.313C3.17444 6.46131 3.21751 6.61012 3.3107 6.72668L4.8107 8.60168C4.8666 8.67165 4.9384 8.72726 5.02012 8.76388C5.10185 8.8005 5.19114 8.81708 5.28056 8.81224C5.36999 8.8074 5.45696 8.78128 5.53426 8.73604C5.61155 8.69081 5.67692 8.62778 5.72495 8.55218L8.34995 4.42718Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_14979">
                                        <rect width="12" height="12" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                Und vieles mehr!
                            </li>
                        </ul>
                        <div className='box-outer device-box'>
                            <form className='theme-from'>
                                <div className='form-group'>
                                    <label>E-Mail</label>
                                    <input type='email' className='form-control' value={email} onChange={(e)=>handleEmail(e.target.value)} placeholder='you@company.com' required/>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <input type='checkbox' id='terms' />
                                    {/* <label htmlFor='terms' style={{fontSize:'14px',color:"#475467",marginLeft:'5px'}}>
                                        Ich stimme der Datenschutzerklärung zu und wohne nicht in der EU.
                                    </label> */}
                                </div>
                            </form>
                        </div>
                        <br/>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('roof-type')}>
                                <GrLinkPrevious /> Zurück
                            </div>
                            <div className={email === ""?'btn theme-btn disabled':'btn theme-btn'} onClick={()=>{handleClick('contact')}}>
                                Weiter <GrLinkNext/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
