import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function DeviceCount({handleClick, handleDeviceCount,devicecount}) {
  return (
    <div className='deviceconsumption'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Haben Sie Geräte, die viel Strom verbrauchen?</h2>
                        <p>Bitte wählen Sie die vorhandenen Verbraucher aus. Geben Sie auch Geräte an,<br/>die Sie in Zukunft installieren möchten.</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <div className='theme-box'>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/water-pump.svg' />
                                    </div>
                                    <h5>Wärmepumpenheizung</h5>
                                </div>
                                <input type="checkbox" id="water-pump" className="custom-checkbox" onChange={(e)=>handleDeviceCount('Heatpump',e.target.checked)} checked={devicecount.includes('Heatpump')}/>
                                <label htmlFor="water-pump" className="checkbox-label"></label>
                            </div>
                            <div className='theme-box'>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/heater.svg' />
                                    </div>
                                    <h5>Elektrische Heizung</h5>
                                </div>
                                <input type="checkbox" id="heater" className="custom-checkbox" onChange={(e)=>handleDeviceCount('ElectricHeating',e.target.checked)} checked={devicecount.includes('ElectricHeating')}/>
                                <label htmlFor="heater" className="checkbox-label"></label>
                            </div>
                            <div className='theme-box'>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/hot-water.svg' />
                                    </div>
                                    <h5>Elektrischer Boiler</h5>
                                </div>
                                <input type="checkbox" id="hot-water" className="custom-checkbox" onChange={(e)=>handleDeviceCount('ElectricBoiler',e.target.checked)} checked={devicecount.includes('ElectricBoiler')}/>
                                <label htmlFor="hot-water" className="checkbox-label"></label>
                            </div>
                            <div className='theme-box'>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/charging-station.svg' />
                                    </div>
                                    <h5>Elektrofahrzeug-Ladestation</h5>
                                </div>
                                <input type="checkbox" id="charging-station" className="custom-checkbox" onChange={(e)=>handleDeviceCount('ElectricVehicle',e.target.checked)} checked={devicecount.includes('ElectricVehicle')}/>
                                <label htmlFor="charging-station" className="checkbox-label"></label>
                            </div>
                            <div className='theme-box'>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/swimming-pool.svg' />
                                    </div>
                                    <h5>Schwimmbad und/oder Sauna</h5>
                                </div>
                                <input type="checkbox" id="swimming-pool" className="custom-checkbox" onChange={(e)=>handleDeviceCount('Sauna',e.target.checked)} checked={devicecount.includes('Sauna')}/>
                                <label htmlFor="swimming-pool" className="checkbox-label"></label>
                            </div>
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('people-count')}><GrLinkPrevious /> Zurück </div>
                            <div className={devicecount.length <= 0? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('maps')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
