import React from 'react'
import { FiCheck } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { GoDot } from "react-icons/go";

function Indicator({activeState}) {
  return (
    <div className="progress-container">
      <div className={`step ${activeState === 'email' ? 'active' : ''}`}>
        <div className="step-icon">
          {activeState === 'email'?(
            <FiCheck />
          ) : (
            <GoDotFill />
          )}
        </div>
        <div style={{marginTop:'55px',marginLeft:"24px",fontWeight:600,fontSize:'14px',color:"#344054"}}>Aktuelle Situation</div>
      </div>
      <div className={`step ${activeState === 'email' || activeState === 'contact' ? 'active' : ''}`}>
        <div className={`step-icon ${activeState === 'email' || activeState === 'contact' ? '' : 'incomplete'}`}>
          {activeState === 'contact'?(
            <FiCheck />
          ) : (
            <GoDotFill />
          )}
        </div>
        <div style={{marginTop:'55px',marginLeft:"24px",fontWeight:600,fontSize:'14px',color:"#344054"}}>Installationsdetails</div>
      </div>
      <div className={`step ${activeState === 'contact' ? 'active' : ''}`}>
        <div className={`step-icon ${activeState === 'contact' ? '' : 'incomplete'}`}>
          <GoDot />
        </div>
        <div style={{marginTop:'55px',marginLeft:"24px",fontWeight:600,fontSize:'14px',color:"#344054"}}>Ihr Angebot mit Preis</div>
      </div>
    </div>
  )
}

export default Indicator