import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { Link } from 'react-router-dom';

export default function ContactForm({handleClick,handleContactForm,contactform}) {
  return (
    <div className='contactform'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Ihre Kontaktdaten für das detaillierte Angebot</h2>
                        <p>Wir senden Ihnen gerne das Angebot<br/> persönlich zu. Dafür benötigen wir Ihre Kontaktdaten.</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <form className='theme-from'>
                                <div className='d-flex justify-content-around'>
                                    <label className="custom-radio">
                                        <input htmlFor='mr' type="radio" value="Herr" name="title" onChange={(e)=>handleContactForm(e)} id='mr'/>
                                        <span className="checkmark"></span>
                                        <span className="label-text">Herr</span>
                                    </label>
                                    <label htmlFor='ms' className="custom-radio">
                                        <input type="radio" name="title" value="Frau" onChange={(e)=>handleContactForm(e)} id='ms' />
                                        <span className="checkmark"></span>
                                        <span className="label-text">Frau</span>
                                    </label>
                                    <label htmlFor='family' className="custom-radio">
                                        <input type="radio" name="title" value="Familie" onChange={(e)=>handleContactForm(e)} id='family'/>
                                        <span className="checkmark"></span>
                                        <span className="label-text">Familie</span>
                                    </label>
                                </div>
                                <br/>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='fname'>Vorname</label>
                                            <input id='fname' name='fname' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='Vorname' required/>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='lname'>Nachname</label>
                                            <input id='lname' name='lname' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='Nachname' />
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='phone'>Telefonnummer</label>
                                            <input id='phone' name='phone' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='+49 (555) 000-0000' required/>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='street'>Strasse</label>
                                            <input id='street' name='street' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='Geben Sie Ihre Straße ein' required/>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='zip'>Postleitzahl</label>
                                            <input id='zip' name='zipcode' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='PLZ eingeben' required/>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='form-group'>
                                            <label htmlFor='Comments'>Kommentare</label>
                                            <textarea id='Comments' name='comments' onChange={(e)=>handleContactForm(e)} type='text' className='form-control' placeholder='Schreiben Sie etwas' style={{height:'134px'}} />
                                        </div>
                                    </div>
                                </div>
                                <div className='grp-btns' style={{maxWidth:'100%',marginLeft:0,marginRight:0}}>
                                    <div className='btn theme-btn alt'><GrLinkPrevious /> Zurück </div>
                                    <Link to="/finalpage" className={Object.keys(contactform).length === 0? 'btn theme-btn disabled' : 'btn theme-btn'}>Absenden <GrLinkNext/></Link>
                                </div>
                            </form>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
