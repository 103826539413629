import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { useState } from 'react';

export default function KwpInput({handleClick,handleKWP,kwp}) {

  return (
    <div className='roofSize'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Wie viel Kilowatt-Peak<br/>möchten Sie ungefähr?</h2>
                        <p>Geben Sie Ihren KWP ein</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <input type='number' value={kwp} onChange={(e)=>handleKWP(e.target.value)} className='theme-box w-100 number-input' style={{height:'60px'}} />
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('roof-type')}><GrLinkPrevious /> Zurück </div>
                            <div className={kwp === null? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('email')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
