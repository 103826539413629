import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import { useState } from 'react';
import Proccess from './Proccess';
import FinalPage from './FinalPage';

function App() {
  const [process, setprocess] = useState(true)
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
          <Routes>
            <Route path="/" element={<Proccess />} exact/>
            <Route path="/finalpage" element={<FinalPage/>} exact/>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
