import React from 'react';
import { FiChevronDown } from "react-icons/fi";
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <div className='header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-6'>
                        <Link to="/" className='logo'>
                            <img src='/logo.png' alt='logo' />
                        </Link>
                    </div>
                    <div className='col-lg-6 col-6'>
                        <div className='langinput'>
                            <div className='langselected'>
                                <div className='d-flex align-items-center'>
                                    <img src='/DEflag.svg' alt='lang' />
                                    <p>Deutsch</p>
                                </div>
                                <FiChevronDown />
                            </div>
                            <ul>
                                <li><img src='/FRflag.svg' alt='lang' />Franch</li>
                                <li><img src='/USflag.svg' alt='lang' />USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
