import React,{useState} from 'react'
import axios from 'axios';
import { CiLocationOn } from "react-icons/ci";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function Maps({handleClick,handleSetAddress,address}) {
    const [typingTimeout, setTypingTimeout] = useState(null);
    const [addresslist, setaddresslist] = useState([]);
    const [query, setquery] = useState('');

    const handleDebouncedFunction = async (e) => {
      if (e.target.value !== "") {
        const data = await axios({method:'GET',url:`https://api3.geo.admin.ch/rest/services/api/SearchServer?searchText=${e.target.value}&type=locations&origins=address&limit=8`})
        .then((res)=>{
          if (res.status === 200) {
            setaddresslist(res.data.results)
          }
        })
      }else{
        setaddresslist([])
      }
    };
    const handleMapAddress = async (e) =>{
      setquery(e.target.value)
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const newTimeout = setTimeout(() => {
        handleDebouncedFunction(e);
      }, 1000);
      setTypingTimeout(newTimeout);
    }
    
    const handleAddressClick = (item) =>{
      handleSetAddress({label:item.attrs?.label,lat:item?.attrs?.lat,lon:item?.attrs?.lon,id:item?.id})
      setaddresslist([])
      setquery(item.attrs?.label.replace('<b>','').replace('</b>',''));
    }
      
  return (
    <div className='maps'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                      <h2>Welche Dachfläche eignet sich für Ihre Solaranlage?</h2>
                      <p>Suchen Sie Ihre Adresse</p>
                      <div className='map-inner'>
                        <div className='map-inner-top'>
                          <img src='/map.png' alt='Karte' />
                          <input 
                            type='text' 
                            placeholder='Geben Sie Ihre Adresse ein' 
                            className='form-control' 
                            value={query} 
                            onChange={(e)=>handleMapAddress(e)} 
                          />
                          <ul>
                            {addresslist.map((item,index)=>(
                              <li key={index} onClick={()=>{handleAddressClick(item)}} dangerouslySetInnerHTML={{__html:item.attrs?.label}}></li>
                            ))}
                          </ul>
                        </div>
                        <div className='map-inner-bottom'>
                          <div className='d-flex align-items-center'>
                            <CiLocationOn />
                            <p dangerouslySetInnerHTML={{__html:address?.label}}></p>
                          </div>
                        </div>
                      </div>
                      <div className='grp-btns'>
                          <div className='btn theme-btn alt' onClick={()=>handleClick('device-count')}><GrLinkPrevious /> Zurück </div>
                          <div className={address === null? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('roof-size')}>Weiter <GrLinkNext/></div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
