import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function PeopleCount({handleClick,handlePeopleCount,peoplecount}) {
  return (
    <div className='people'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Wie viele Personen leben in Ihrem Haushalt?</h2>
                        <p>Bitte wählen Sie die Anzahl der Personen einschliesslich Kinder</p>
                        <div className='box-outer people-box'>
                            <div className={peoplecount === 1? 'theme-box active':'theme-box'} onClick={()=>handlePeopleCount(1)}>
                                <div className='icon-box'>
                                    <img src='/singleperson.svg' />
                                </div>
                                <h5>1 Person</h5>
                            </div>
                            <div className={peoplecount === 2? 'theme-box active':'theme-box'} onClick={()=>handlePeopleCount(2)}>
                                <div className='icon-box'>
                                    <img src='/2person.svg' />
                                </div>
                                <h5>2 Personen</h5>
                            </div>
                            <div className={peoplecount === 3? 'theme-box active':'theme-box'} onClick={()=>handlePeopleCount(3)}>
                                <div className='icon-box'>
                                    <img src='/3person.svg' />
                                </div>
                                <h5>3 Personen</h5>
                            </div>
                            <div className={peoplecount === 4? 'theme-box active':'theme-box'} onClick={()=>handlePeopleCount(4)}>
                                <div className='icon-box'>
                                    <img src='/4person.svg' />
                                </div>
                                <h5>4 Personen</h5>
                            </div>
                            <div className={peoplecount === 5? 'theme-box active':'theme-box'} onClick={()=>handlePeopleCount(5)}>
                                <div className='icon-box'>
                                    <img src='/5person.svg' />
                                </div>
                                <h5>5 Personen</h5>
                            </div>
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('hero-section')}><GrLinkPrevious /> Zurück </div>
                            <div className={peoplecount === null? 'btn theme-btn disabled' : 'btn theme-btn'} onClick={()=>handleClick('device-count')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
