import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function RoofAngle({handleClick,handleRoofAngle,roofangle}) {
  return (
    <div className='roofSize'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Wie ist der Neigungswinkel Ihres Daches?</h2>
                        <p>Wählen Sie aus, wie steil Ihr Dach gebaut ist</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <div className={roofangle === 0? 'theme-box active':'theme-box'} onClick={()=>handleRoofAngle(0)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/flatroof.svg' />
                                    </div>
                                    <h5>Flachdach (Neigung 0<sup>0</sup>)</h5>
                                </div>
                            </div>
                            <div className={roofangle === 15? 'theme-box active':'theme-box'} onClick={()=>handleRoofAngle(15)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/standard.svg' />
                                    </div>
                                    <h5>Eher flach (Neigung 15<sup>0</sup>)</h5>
                                </div>
                            </div>
                            <div className={roofangle === 30? 'theme-box active':'theme-box'} onClick={()=>handleRoofAngle(30)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/ratherflat.svg' />
                                    </div>
                                    <h5>Standard (Neigung 30<sup>0</sup>)</h5>
                                </div>
                            </div>
                            <div className={roofangle === 50? 'theme-box active':'theme-box'} onClick={()=>handleRoofAngle(50)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/pitchedroof.svg' />
                                    </div>
                                    <h5>Steildach (Neigung 50<sup>0</sup>)</h5>
                                </div>
                            </div>
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('roof-size')}><GrLinkPrevious /> Zurück </div>
                            <div className={roofangle === null?'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('roof-type')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
