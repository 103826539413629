import React, { useState } from 'react'
import Indicator from './components/Indicator'
import HeroSection from './components/HeroSection'
import PeopleCount from './components/PeopleCount'
import DeviceCount from './components/DeviceCount'
import RoofSize from './components/RoofSize'
import RoofAngle from './components/RoofAngle'
import RoofType from './components/RoofType'
import EmailVerification from './components/EmailVerification'
import ContactForm from './components/ContactForm'
import Maps from './components/Maps'
import axios from 'axios';
import Result from './components/Result'
import KwpInput from './components/KwpInput'
import Footer from './components/Footer'
export default function Proccess() {
    const [activeState, setactiveState] = useState('hero-section')
    const [selectedCiteria, setselectedCiteria] = useState(null)
    const [peoplecount, setpeoplecount] = useState(null)
    const [devicecount, setdevicecount] = useState([])
    const [roofsize, setroofsize] = useState(null)
    const [roofangle, setroofangle] = useState(null)
    const [roofCoverType, setroofCoverType] = useState(null)
    const [email, setemail] = useState("")
    const [contactform, setcontactform] = useState({})
    const [address, setaddress] = useState(null)
    const [kwp, setKwp] = useState(10)
    
    const handleClick = (state) => {
        setactiveState(state)
    }
    const handleSelectCiteria = (data) => {
        setselectedCiteria(data)
    }
    const handlePeopleCount = (data) => {
        setpeoplecount(data)
    }
    const handleRoofSize = (data) => {
        setroofsize(data)
    }
    const handleRoofAngle = (data) => {
        setroofangle(data)
    }
    const handleKWP = (data) => {
        console.log(kwp);
        
        setKwp(data)
    }
    const handleRoofCoverType = (data) => {
        setroofCoverType(data)
    }
    const handleEmail = (data) => {
        setemail(data)
    }
    const handleContactForm = (e) => {
        setcontactform(prev=>{
            return{...prev, [e.target.name]:e.target.value}
        })
    }
    const handleSetAddress = (data) => {
        setaddress(data)
    }
    const handleDeviceCount = (data,checked) => {
        if (checked) {
            setdevicecount((prevOptions) => [...prevOptions, data])
        } else {
            setdevicecount(
                (prevOptions) => prevOptions.filter((option) => option !== data)
            )
        }
    }


    const constants = {
        modulePerKwp: 158.6666667,
        inverterPerKwp: 80,
        mountingSystemPerKwp: 130,
        dcInstallationPerKwp: 80,
        acInstallationFlatRate: 2500,
        travelCostsFlatRate: 800,
        planningFlatRate: 375,
        insuranceFlatRate: 100,
        rentalCostsFlatRate: 240,
        backOfficeFlatRate: 2500,
        commissionCostsPerKwp: 200,
        profitMargin: 0.3,
        taxes: 0.26,
        vat: 0.081
    }

    const gatherData = () =>{
        let data = {
            BuildingTypeOfUse:[selectedCiteria],
            BuildingVolume:0,
            BuildingGroundArea:0,
            BuildingRoofArea:0,
            BuildingEavesHeight:0,
            BuildingRidgeHeight:0,
            BuildingRoofOverhangArea:0,
            BuildingWallArea:0,
            BuildingAboveSea:0,
            LatLonCoordinates:`${address?.lat},${address?.lon}`,
            BuildingNumberInhabitants:peoplecount,
            BuildingLevels:0,
            RoofArea:roofsize,
            RoofSlope:`${roofangle}`,
            IsRoofShadow:"Unknown",
            RoofType:roofangle > 0? "SlopedRoof" : "FlatRoof",
            RoofCoverType:roofCoverType,
            MountingType:"InRoof",
            MajorEnergyConsumers:["Heatpump","ElectricHeating"],
            EnergyConsumptionHt:15889.999999999998,
            EnergyConsumptionLt:6810,
            Egid:"11515016"
        }

        localStorage.setItem("userData", JSON.stringify(data))
        
        // Calculations
        const materialCosts = kwp * (constants.modulePerKwp + constants.inverterPerKwp + constants.mountingSystemPerKwp)
        const laborCosts = (kwp * constants.dcInstallationPerKwp) + constants.acInstallationFlatRate + constants.travelCostsFlatRate
        const fixedCosts = constants.planningFlatRate + constants.insuranceFlatRate + constants.rentalCostsFlatRate + 
                            constants.backOfficeFlatRate + (kwp * constants.commissionCostsPerKwp)

        const totalCosts = materialCosts + laborCosts + fixedCosts
        const profitMargin = totalCosts * constants.profitMargin
        const priceExclVat = totalCosts + profitMargin
        const taxes = priceExclVat * constants.taxes
        const priceInclTaxes = priceExclVat + taxes
        const vatAmount = priceInclTaxes * constants.vat
        const finalPrice = priceInclTaxes + vatAmount
        const amount = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(finalPrice)
        localStorage.setItem("amount", JSON.stringify(amount))
        
    }
    
  return (
    <div>
        <h3 className='hero-title'><h1>Nr. 1</h1>Kostenloser Solarrechner von Swiss Sun Solutions GmbH.<br/>Überprüfen Sie jetzt Ihre Solarkosten!</h3>
        <Indicator activeState={activeState}/>
        <br/><br/>
        {activeState === 'hero-section'?(
            <HeroSection handleClick={handleClick} handleSelectCiteria={handleSelectCiteria} selectedCiteria={selectedCiteria}/>
        ) : activeState === 'people-count'? (
            <PeopleCount handleClick={handleClick} handlePeopleCount={handlePeopleCount} peoplecount={peoplecount} />
        ) : activeState === 'device-count'? (
            <DeviceCount handleClick={handleClick} handleDeviceCount={handleDeviceCount} devicecount={devicecount} />
        ) : activeState === 'maps'? (
            <Maps handleClick={handleClick} handleSetAddress={handleSetAddress} address={address} />
        ) : activeState === 'roof-size'? (
            <RoofSize handleClick={handleClick} handleRoofSize={handleRoofSize} roofsize={roofsize} />
        ) : activeState === 'roof-angle'? (
            <RoofAngle handleClick={handleClick} handleRoofAngle={handleRoofAngle} roofangle={roofangle} />
        ) : activeState === 'roof-type'? (
            <RoofType handleClick={handleClick} handleRoofCoverType={handleRoofCoverType} roofCoverType={roofCoverType}/>
        ) : activeState === 'kwp'? (
            <KwpInput handleClick={handleClick} handleKWP={handleKWP} kwp={kwp}/>
        ) : activeState === 'email'? (
            <EmailVerification handleClick={handleClick} handleEmail={handleEmail} email={email} gatherData={gatherData} />
        ) : activeState === 'contact'? (
            <ContactForm handleClick={handleClick} handleContactForm={handleContactForm} contactform={contactform} />
        ) : ""}
        <Footer />
    </div>
  )
}
