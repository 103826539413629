import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='footer'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 col-md-6'>
                    <p>Copyright © 2024 Swiss Sun Solutions GmbH. All rights reserved.</p>
                </div>
                <div className='col-lg-6 col-md-6'>
                    <Link to="https://www.omegamarketing.ch/" target='_blank' >Powered By Omega Marketing</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
