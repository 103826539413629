import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { useState } from 'react';

export default function RoofSize({handleClick,handleRoofSize,roofsize}) {
  const [value, setValue] = useState(10);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    handleRoofSize(Number(e.target.value))
  };
  const progress = ((value - 10) / (500 - 10)) * 100;
  return (
    <div className='roofSize'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Wie gross soll die Fläche sein, die Sie<br/>mit Solarmodulen bedecken möchten?</h2>
                        <p>Wählen Sie die Grösse Ihres Daches</p>
                        <br/>
                        <div className='box-outer device-box'>
                            <div className={roofsize === 45? 'theme-box active':'theme-box'} onClick={()=>handleRoofSize(45)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/roofing.svg' />
                                    </div>
                                    <h5>Mittlere Dachfläche (23 m² bis 45 m²)</h5>
                                </div>
                            </div>
                            <div className={roofsize === 90? 'theme-box active':'theme-box'} onClick={()=>handleRoofSize(90)}>
                                <div>
                                    <div className='icon-box'>
                                        <img src='/roof.svg' />
                                    </div>
                                    <h5>Große Dachfläche (bis zu 90 m²)</h5>
                                </div>
                            </div>
                            <div className='devider'>
                                <hr />
                                <p>oder</p>
                                <hr />
                            </div>
                            <input type='number' value={value} className='theme-box w-100 number-input' style={{height:'60px'}} />
                            <input 
                                type='range' 
                                className="range-slider" 
                                min="10" 
                                max="500" 
                                value={value}
                                onChange={handleInputChange}
                                style={{ "--progress": `${progress}%` }}
                            />
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt' onClick={()=>handleClick('device-count')}><GrLinkPrevious /> Zurück </div>
                            <div className={roofsize === null? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('roof-angle')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
