import React, { useEffect, useState,useRef } from 'react'
import { AiOutlineDownload } from "react-icons/ai";
import generatePDF from 'react-to-pdf';

export default function Result() {
    const [data, setdata] = useState()
    const [amount, setamount] = useState()

    useEffect(() => {
        setdata(JSON.parse(localStorage.getItem('userData')))
        setamount(localStorage.getItem('amount'))
    }, [])
    
    const targetRef = useRef();
  return (
    <div className='result' ref={targetRef}>
        <div className='container'>
            <br/>
            <div className='singleCard overview-card'>
                <h1>Ihre Projektübersicht</h1>
                <br/>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='single-card-img' style={{maxHeight:'400px'}}>
                            <img src='/Carousel-image.png' alt='solar' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center'>
                        <div className='single-card-content'>
                            <h1>Die schöne Variante zum guten Preis</h1>
                            <br/>
                            <ul>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_16_15109)">
                                        <path d="M13.899 3.18783C14.07 3.60128 14.3981 3.92992 14.8113 4.1015L16.2602 4.70165C16.6737 4.87292 17.0022 5.20143 17.1735 5.61491C17.3448 6.0284 17.3448 6.49299 17.1735 6.90647L16.5738 8.3543C16.4024 8.76797 16.4022 9.23303 16.5743 9.6465L17.173 11.0939C17.2579 11.2987 17.3016 11.5182 17.3016 11.7399C17.3017 11.9616 17.258 12.1811 17.1732 12.386C17.0883 12.5908 16.964 12.7769 16.8072 12.9336C16.6504 13.0904 16.4643 13.2147 16.2594 13.2994L14.8116 13.8991C14.3981 14.0701 14.0695 14.3983 13.8979 14.8114L13.2978 16.2603C13.1265 16.6738 12.798 17.0023 12.3845 17.1736C11.971 17.3449 11.5064 17.3449 11.0929 17.1736L9.64511 16.5739C9.23161 16.403 8.7672 16.4034 8.35396 16.5748L6.90508 17.1741C6.49183 17.345 6.02764 17.3449 5.6145 17.1737C5.20135 17.0026 4.87302 16.6745 4.70163 16.2614L4.10131 14.8121C3.93035 14.3987 3.6022 14.07 3.18901 13.8985L1.74014 13.2983C1.32683 13.1271 0.998417 12.7988 0.827083 12.3856C0.655748 11.9723 0.655516 11.5079 0.826437 11.0945L1.42615 9.64669C1.597 9.23319 1.59665 8.76877 1.42518 8.35553L0.826328 6.90562C0.741422 6.70083 0.697703 6.48131 0.697668 6.25961C0.697632 6.03792 0.741281 5.81839 0.82612 5.61357C0.91096 5.40874 1.03533 5.22265 1.19212 5.06591C1.3489 4.90917 1.53504 4.78486 1.73989 4.70009L3.18772 4.10038C3.60082 3.92957 3.92926 3.60184 4.10096 3.18912L4.70111 1.74024C4.87238 1.32676 5.20089 0.998247 5.61438 0.826976C6.02786 0.655705 6.49245 0.655705 6.90593 0.826976L8.35377 1.42669C8.76726 1.59754 9.23168 1.59719 9.64492 1.42572L11.0944 0.827905C11.5078 0.65673 11.9723 0.656765 12.3857 0.828003C12.7991 0.99924 13.1276 1.32766 13.2989 1.74104L13.8992 3.19034L13.899 3.18783Z" fill="#FA9D08"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5249 6.64084C12.6452 6.45197 12.6855 6.22307 12.6369 6.0045C12.5884 5.78593 12.455 5.59558 12.2662 5.47534C12.0773 5.3551 11.8484 5.31481 11.6298 5.36333C11.4113 5.41186 11.2209 5.54522 11.1007 5.73409L7.79654 10.926L6.28454 9.03596C6.14476 8.86112 5.94124 8.74897 5.71876 8.72418C5.49629 8.69939 5.27308 8.76399 5.09823 8.90378C4.92339 9.04356 4.81123 9.24708 4.78644 9.46955C4.76165 9.69203 4.82626 9.91524 4.96604 10.0901L7.21604 12.9026C7.2999 13.0075 7.4076 13.0909 7.53018 13.1459C7.65277 13.2008 7.7867 13.2257 7.92084 13.2184C8.05498 13.2112 8.18544 13.172 8.30138 13.1041C8.41732 13.0363 8.51539 12.9417 8.58742 12.8283L12.5249 6.64084Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_15109">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    Leistungsstarke Solaranlage für Ihr Dach
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_16_15109)">
                                        <path d="M13.899 3.18783C14.07 3.60128 14.3981 3.92992 14.8113 4.1015L16.2602 4.70165C16.6737 4.87292 17.0022 5.20143 17.1735 5.61491C17.3448 6.0284 17.3448 6.49299 17.1735 6.90647L16.5738 8.3543C16.4024 8.76797 16.4022 9.23303 16.5743 9.6465L17.173 11.0939C17.2579 11.2987 17.3016 11.5182 17.3016 11.7399C17.3017 11.9616 17.258 12.1811 17.1732 12.386C17.0883 12.5908 16.964 12.7769 16.8072 12.9336C16.6504 13.0904 16.4643 13.2147 16.2594 13.2994L14.8116 13.8991C14.3981 14.0701 14.0695 14.3983 13.8979 14.8114L13.2978 16.2603C13.1265 16.6738 12.798 17.0023 12.3845 17.1736C11.971 17.3449 11.5064 17.3449 11.0929 17.1736L9.64511 16.5739C9.23161 16.403 8.7672 16.4034 8.35396 16.5748L6.90508 17.1741C6.49183 17.345 6.02764 17.3449 5.6145 17.1737C5.20135 17.0026 4.87302 16.6745 4.70163 16.2614L4.10131 14.8121C3.93035 14.3987 3.6022 14.07 3.18901 13.8985L1.74014 13.2983C1.32683 13.1271 0.998417 12.7988 0.827083 12.3856C0.655748 11.9723 0.655516 11.5079 0.826437 11.0945L1.42615 9.64669C1.597 9.23319 1.59665 8.76877 1.42518 8.35553L0.826328 6.90562C0.741422 6.70083 0.697703 6.48131 0.697668 6.25961C0.697632 6.03792 0.741281 5.81839 0.82612 5.61357C0.91096 5.40874 1.03533 5.22265 1.19212 5.06591C1.3489 4.90917 1.53504 4.78486 1.73989 4.70009L3.18772 4.10038C3.60082 3.92957 3.92926 3.60184 4.10096 3.18912L4.70111 1.74024C4.87238 1.32676 5.20089 0.998247 5.61438 0.826976C6.02786 0.655705 6.49245 0.655705 6.90593 0.826976L8.35377 1.42669C8.76726 1.59754 9.23168 1.59719 9.64492 1.42572L11.0944 0.827905C11.5078 0.65673 11.9723 0.656765 12.3857 0.828003C12.7991 0.99924 13.1276 1.32766 13.2989 1.74104L13.8992 3.19034L13.899 3.18783Z" fill="#FA9D08"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5249 6.64084C12.6452 6.45197 12.6855 6.22307 12.6369 6.0045C12.5884 5.78593 12.455 5.59558 12.2662 5.47534C12.0773 5.3551 11.8484 5.31481 11.6298 5.36333C11.4113 5.41186 11.2209 5.54522 11.1007 5.73409L7.79654 10.926L6.28454 9.03596C6.14476 8.86112 5.94124 8.74897 5.71876 8.72418C5.49629 8.69939 5.27308 8.76399 5.09823 8.90378C4.92339 9.04356 4.81123 9.24708 4.78644 9.46955C4.76165 9.69203 4.82626 9.91524 4.96604 10.0901L7.21604 12.9026C7.2999 13.0075 7.4076 13.0909 7.53018 13.1459C7.65277 13.2008 7.7867 13.2257 7.92084 13.2184C8.05498 13.2112 8.18544 13.172 8.30138 13.1041C8.41732 13.0363 8.51539 12.9417 8.58742 12.8283L12.5249 6.64084Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_15109">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    Innovativer Wechselrichter Made in Germany
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_16_15109)">
                                        <path d="M13.899 3.18783C14.07 3.60128 14.3981 3.92992 14.8113 4.1015L16.2602 4.70165C16.6737 4.87292 17.0022 5.20143 17.1735 5.61491C17.3448 6.0284 17.3448 6.49299 17.1735 6.90647L16.5738 8.3543C16.4024 8.76797 16.4022 9.23303 16.5743 9.6465L17.173 11.0939C17.2579 11.2987 17.3016 11.5182 17.3016 11.7399C17.3017 11.9616 17.258 12.1811 17.1732 12.386C17.0883 12.5908 16.964 12.7769 16.8072 12.9336C16.6504 13.0904 16.4643 13.2147 16.2594 13.2994L14.8116 13.8991C14.3981 14.0701 14.0695 14.3983 13.8979 14.8114L13.2978 16.2603C13.1265 16.6738 12.798 17.0023 12.3845 17.1736C11.971 17.3449 11.5064 17.3449 11.0929 17.1736L9.64511 16.5739C9.23161 16.403 8.7672 16.4034 8.35396 16.5748L6.90508 17.1741C6.49183 17.345 6.02764 17.3449 5.6145 17.1737C5.20135 17.0026 4.87302 16.6745 4.70163 16.2614L4.10131 14.8121C3.93035 14.3987 3.6022 14.07 3.18901 13.8985L1.74014 13.2983C1.32683 13.1271 0.998417 12.7988 0.827083 12.3856C0.655748 11.9723 0.655516 11.5079 0.826437 11.0945L1.42615 9.64669C1.597 9.23319 1.59665 8.76877 1.42518 8.35553L0.826328 6.90562C0.741422 6.70083 0.697703 6.48131 0.697668 6.25961C0.697632 6.03792 0.741281 5.81839 0.82612 5.61357C0.91096 5.40874 1.03533 5.22265 1.19212 5.06591C1.3489 4.90917 1.53504 4.78486 1.73989 4.70009L3.18772 4.10038C3.60082 3.92957 3.92926 3.60184 4.10096 3.18912L4.70111 1.74024C4.87238 1.32676 5.20089 0.998247 5.61438 0.826976C6.02786 0.655705 6.49245 0.655705 6.90593 0.826976L8.35377 1.42669C8.76726 1.59754 9.23168 1.59719 9.64492 1.42572L11.0944 0.827905C11.5078 0.65673 11.9723 0.656765 12.3857 0.828003C12.7991 0.99924 13.1276 1.32766 13.2989 1.74104L13.8992 3.19034L13.899 3.18783Z" fill="#FA9D08"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5249 6.64084C12.6452 6.45197 12.6855 6.22307 12.6369 6.0045C12.5884 5.78593 12.455 5.59558 12.2662 5.47534C12.0773 5.3551 11.8484 5.31481 11.6298 5.36333C11.4113 5.41186 11.2209 5.54522 11.1007 5.73409L7.79654 10.926L6.28454 9.03596C6.14476 8.86112 5.94124 8.74897 5.71876 8.72418C5.49629 8.69939 5.27308 8.76399 5.09823 8.90378C4.92339 9.04356 4.81123 9.24708 4.78644 9.46955C4.76165 9.69203 4.82626 9.91524 4.96604 10.0901L7.21604 12.9026C7.2999 13.0075 7.4076 13.0909 7.53018 13.1459C7.65277 13.2008 7.7867 13.2257 7.92084 13.2184C8.05498 13.2112 8.18544 13.172 8.30138 13.1041C8.41732 13.0363 8.51539 12.9417 8.58742 12.8283L12.5249 6.64084Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_15109">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    Schwarze Module, Leistungssteigerung, Batterie, Smart Home
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g clip-path="url(#clip0_16_15109)">
                                        <path d="M13.899 3.18783C14.07 3.60128 14.3981 3.92992 14.8113 4.1015L16.2602 4.70165C16.6737 4.87292 17.0022 5.20143 17.1735 5.61491C17.3448 6.0284 17.3448 6.49299 17.1735 6.90647L16.5738 8.3543C16.4024 8.76797 16.4022 9.23303 16.5743 9.6465L17.173 11.0939C17.2579 11.2987 17.3016 11.5182 17.3016 11.7399C17.3017 11.9616 17.258 12.1811 17.1732 12.386C17.0883 12.5908 16.964 12.7769 16.8072 12.9336C16.6504 13.0904 16.4643 13.2147 16.2594 13.2994L14.8116 13.8991C14.3981 14.0701 14.0695 14.3983 13.8979 14.8114L13.2978 16.2603C13.1265 16.6738 12.798 17.0023 12.3845 17.1736C11.971 17.3449 11.5064 17.3449 11.0929 17.1736L9.64511 16.5739C9.23161 16.403 8.7672 16.4034 8.35396 16.5748L6.90508 17.1741C6.49183 17.345 6.02764 17.3449 5.6145 17.1737C5.20135 17.0026 4.87302 16.6745 4.70163 16.2614L4.10131 14.8121C3.93035 14.3987 3.6022 14.07 3.18901 13.8985L1.74014 13.2983C1.32683 13.1271 0.998417 12.7988 0.827083 12.3856C0.655748 11.9723 0.655516 11.5079 0.826437 11.0945L1.42615 9.64669C1.597 9.23319 1.59665 8.76877 1.42518 8.35553L0.826328 6.90562C0.741422 6.70083 0.697703 6.48131 0.697668 6.25961C0.697632 6.03792 0.741281 5.81839 0.82612 5.61357C0.91096 5.40874 1.03533 5.22265 1.19212 5.06591C1.3489 4.90917 1.53504 4.78486 1.73989 4.70009L3.18772 4.10038C3.60082 3.92957 3.92926 3.60184 4.10096 3.18912L4.70111 1.74024C4.87238 1.32676 5.20089 0.998247 5.61438 0.826976C6.02786 0.655705 6.49245 0.655705 6.90593 0.826976L8.35377 1.42669C8.76726 1.59754 9.23168 1.59719 9.64492 1.42572L11.0944 0.827905C11.5078 0.65673 11.9723 0.656765 12.3857 0.828003C12.7991 0.99924 13.1276 1.32766 13.2989 1.74104L13.8992 3.19034L13.899 3.18783Z" fill="#FA9D08"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5249 6.64084C12.6452 6.45197 12.6855 6.22307 12.6369 6.0045C12.5884 5.78593 12.455 5.59558 12.2662 5.47534C12.0773 5.3551 11.8484 5.31481 11.6298 5.36333C11.4113 5.41186 11.2209 5.54522 11.1007 5.73409L7.79654 10.926L6.28454 9.03596C6.14476 8.86112 5.94124 8.74897 5.71876 8.72418C5.49629 8.69939 5.27308 8.76399 5.09823 8.90378C4.92339 9.04356 4.81123 9.24708 4.78644 9.46955C4.76165 9.69203 4.82626 9.91524 4.96604 10.0901L7.21604 12.9026C7.2999 13.0075 7.4076 13.0909 7.53018 13.1459C7.65277 13.2008 7.7867 13.2257 7.92084 13.2184C8.05498 13.2112 8.18544 13.172 8.30138 13.1041C8.41732 13.0363 8.51539 12.9417 8.58742 12.8283L12.5249 6.64084Z" fill="white"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_16_15109">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    Innovativer Wechselrichter Made in Germany
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='result-info'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='result-info-card'>
                            <div>
                                <h5>Investition</h5>
                                <h1>{amount}</h1>
                            </div>
                            <div>
                                <svg width="130" height="66" viewBox="0 0 130 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_157_704" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="1" y="1" width="128" height="64">
                                    <rect width="128" height="64" transform="translate(1 1)" fill="url(#paint0_linear_157_704)"/>
                                    </mask>
                                    <g mask="url(#mask0_157_704)">
                                    <path opacity="0.2" d="M5.60606 57.2759L1 65H129V1L127.182 12.0345H124.758C124.111 13.8736 122.77 17.5517 122.576 17.5517C122.382 17.5517 121.121 19.7586 120.515 20.8621L115.303 24.1724L112.273 28.5862L107.424 24.1724L104.879 20.8621L101.242 28.5862H97.4848L94.3333 20.8621C93.1212 19.7586 90.6485 17.5517 90.4545 17.5517C90.2606 17.5517 88.6768 15.3448 87.9091 14.2414H85.9697L81.3636 17.5517L77.3636 20.8621L75.5455 28.5862L70.0909 31.8966L67.303 35.2069L64.1515 40.7241L61.1212 47.9655H59.7879L56.1515 48.6552L52.6364 40.7241L49.6061 35.2069H47.7879L44.6364 31.8966L40.1515 35.2069H35.9091L31.6667 40.7241L27.4242 47.3448L24.2727 53.9655L20.8788 57.2759L16.2727 53.9655L12.6364 57.2759L9 60.5862L5.60606 57.2759Z" fill="#17B26A"/>
                                    </g>
                                    <path d="M1 65L5.60606 57.2759L9 60.5862L12.6364 57.2759L16.2727 53.9655L20.8788 57.2759L24.2727 53.9655L27.4242 47.3448L31.6667 40.7241L35.9091 35.2069H40.1515L44.6364 31.8966L47.7879 35.2069H49.6061L52.6364 40.7241L56.1515 48.6552L59.7879 47.9655H61.1212L64.1515 40.7241L67.303 35.2069L70.0909 31.8966L75.5455 28.5862L77.3636 20.8621L81.3636 17.5517L85.9697 14.2414H87.9091C88.6768 15.3448 90.2606 17.5517 90.4545 17.5517C90.6485 17.5517 93.1212 19.7586 94.3333 20.8621L97.4848 28.5862H101.242L104.879 20.8621L107.424 24.1724L112.273 28.5862L115.303 24.1724L120.515 20.8621C121.121 19.7586 122.382 17.5517 122.576 17.5517C122.77 17.5517 124.111 13.8736 124.758 12.0345H127.182L129 1" stroke="#17B26A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <g opacity="0.2">
                                    <rect x="76" y="5" width="20" height="20" rx="10" stroke="#17B26A" stroke-width="2"/>
                                    </g>
                                    <rect x="81" y="10" width="10" height="10" rx="5" fill="white"/>
                                    <rect x="81" y="10" width="10" height="10" rx="5" stroke="#17B26A" stroke-width="2"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_157_704" x1="64" y1="0" x2="64" y2="64" gradientUnits="userSpaceOnUse">
                                    <stop/>
                                    <stop offset="1" stop-opacity="0"/>
                                    </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <svg className='more' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99935 10.8335C10.4596 10.8335 10.8327 10.4604 10.8327 10.0002C10.8327 9.53993 10.4596 9.16683 9.99935 9.16683C9.53911 9.16683 9.16602 9.53993 9.16602 10.0002C9.16602 10.4604 9.53911 10.8335 9.99935 10.8335Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.99935 5.00016C10.4596 5.00016 10.8327 4.62707 10.8327 4.16683C10.8327 3.70659 10.4596 3.3335 9.99935 3.3335C9.53911 3.3335 9.16602 3.70659 9.16602 4.16683C9.16602 4.62707 9.53911 5.00016 9.99935 5.00016Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.99935 16.6668C10.4596 16.6668 10.8327 16.2937 10.8327 15.8335C10.8327 15.3733 10.4596 15.0002 9.99935 15.0002C9.53911 15.0002 9.16602 15.3733 9.16602 15.8335C9.16602 16.2937 9.53911 16.6668 9.99935 16.6668Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='result-info-card'>
                            <div>
                                <h5>Energiebilanz</h5>
                                <h1>80%</h1>
                            </div>
                            <div>
                                <svg width="140" height="90" viewBox="0 0 140 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5381 80.3251L1.80007 80.2651C1.73007 68.2421 4.61507 56.5651 10.7711 45.3711L32.2441 58.0831C28.6281 64.6411 26.5551 72.2331 26.5381 80.3251Z" fill="#E74056"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2445 58.0819L10.7715 45.3719C16.9285 34.1779 25.5855 25.3919 35.5985 19.3369L48.0745 41.4919C41.5015 45.4849 36.0275 51.2169 32.2445 58.0819Z" fill="#F4A622"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.0736 41.4927L35.5996 19.3357C45.9266 13.0917 58.0356 9.75173 69.9896 9.67773L69.9946 35.3407C61.9986 35.3407 54.5086 37.5847 48.0736 41.4927Z" fill="#FFEC00"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M70.0002 35.34H69.9952L69.9902 9.67705C81.5802 9.60505 93.0052 12.597 103.795 18.985L91.5432 41.2611C85.0272 37.3819 77.5836 35.3354 70.0002 35.34Z" fill="#A2C517"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M91.543 41.2624L103.794 18.9844C114.584 25.3714 123.053 34.3524 128.89 44.7404L107.533 57.6844C103.684 50.8644 98.16 45.1844 91.543 41.2604" fill="#3AA935"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M107.534 57.6842L128.889 44.7422C134.91 55.4572 138.129 67.8422 138.2 80.2462L113.462 80.3122C113.442 72.0602 111.284 64.3302 107.534 57.6842Z" fill="#009640"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M75.8415 38.4532C75.4266 38.3574 75.1266 38.1068 75.0181 37.765C74.9086 37.423 75.0079 37.045 75.2905 36.7284L80.5113 30.8418C80.7994 30.5184 81.1624 30.3787 81.5078 30.4585C81.8531 30.5382 82.1181 30.8229 82.2344 31.2396L84.3482 38.8195C84.4624 39.2277 84.3859 39.611 84.1376 39.8704C83.8902 40.13 83.5108 40.2238 83.0959 40.128L75.8415 38.4532Z" fill="#04508C"/>
                                    <path d="M81.3961 30.9455C81.5534 30.9818 81.6854 31.1277 81.7542 31.3743L83.8669 38.9532C84.0044 39.4465 83.7087 39.7558 83.2097 39.6406L75.9543 37.9656C75.4554 37.8504 75.3252 37.4428 75.665 37.0597L80.8863 31.174C81.0562 30.9824 81.2388 30.9092 81.3961 30.9455ZM81.621 29.9711C81.0911 29.8488 80.5507 30.0453 80.1382 30.5103L74.9169 36.396C74.5149 36.8491 74.3785 37.4036 74.5425 37.9173C74.7065 38.4309 75.1391 38.8037 75.7293 38.9399L82.9847 40.6149C83.5749 40.7512 84.1271 40.6058 84.4997 40.216C84.8723 39.8263 84.9927 39.2681 84.8301 38.6846L82.7174 31.1057C82.5505 30.507 82.1509 30.0934 81.621 29.9711Z" fill="white"/>
                                </svg>
                            </div>
                            <svg className='more' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99935 10.8335C10.4596 10.8335 10.8327 10.4604 10.8327 10.0002C10.8327 9.53993 10.4596 9.16683 9.99935 9.16683C9.53911 9.16683 9.16602 9.53993 9.16602 10.0002C9.16602 10.4604 9.53911 10.8335 9.99935 10.8335Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.99935 5.00016C10.4596 5.00016 10.8327 4.62707 10.8327 4.16683C10.8327 3.70659 10.4596 3.3335 9.99935 3.3335C9.53911 3.3335 9.16602 3.70659 9.16602 4.16683C9.16602 4.62707 9.53911 5.00016 9.99935 5.00016Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9.99935 16.6668C10.4596 16.6668 10.8327 16.2937 10.8327 15.8335C10.8327 15.3733 10.4596 15.0002 9.99935 15.0002C9.53911 15.0002 9.16602 15.3733 9.16602 15.8335C9.16602 16.2937 9.53911 16.6668 9.99935 16.6668Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className='result-details'>
                <div className="offer-card">
                <h2 className="offer-title">Ihr Angebot beinhaltet</h2>
                <hr className="divider" />
                <div className="content-section">
                    <h3 className="section-title">Montage:</h3>
                    <ul className="list">
                    <li>{data?.RoofArea} Quadratmeter Dachfläche</li>
                    <li>
                        Montage, Material, Projektmanagement, Einholung von Genehmigungen und
                        Inspektionen für sichere Arbeit
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            <div className='result-details'>
                <div className="offer-card">
                <h2 className="offer-title">Investition & Berechnungsgrundlage</h2>
                <hr className="divider" />
                <div className="content-section">
                    <h3 className="section-title">Einsparungsschätzung:</h3>
                    <ul className="list">
                    <li>Erwartete Steuerersparnis (gemäss erneuerbarer Energien sind 100% absetzbar)</li>
                    <li>Investitionskosten: {amount}</li>
                    </ul>
                </div>
                <br/>
                <div className="content-section">
                    <h3 className="section-title">Berechnungsgrundlage</h3>
                    <ul className="list">
                    <li>Dachfläche: "{data?.RoofArea} m² Dachfläche"</li>
                    <li>Haushalt: "{data?.BuildingNumberInhabitants}-Personen-Haushalt"</li>
                    <li>Dachtyp: "{data?.RoofSlope}° Dachneigung"</li>
                    </ul>
                </div>
                </div>
            </div>
            <div className='result-details'>
                <div className="offer-card">
                <h2 className="offer-title">Laden Sie jetzt Ihr individuelles Angebot herunter</h2>
                <hr className="divider" />
                <p>Ist alles richtig konfiguriert? Ihr detailliertes Angebot steht zum Download bereit!</p>
                <div className="content-section">
                    <h3 className="section-title">Angebotsdetails:</h3>
                    <ul className="list">
                    <li>Berechnung der Wirtschaftlichkeit Ihrer Anlage</li>
                    <li>Berechnung der Ökologie Ihrer Anlage</li>
                    <li>Detaillierte Auflistung aller Kosten</li>
                    <li>Und vieles mehr!</li>
                    </ul>
                </div>
                <div className='btn theme-btn' onClick={() => generatePDF(targetRef, {filename: 'page.pdf'})}>Angebot herunterladen <AiOutlineDownload /></div>
                </div>
            </div>
        </div>
    </div>
  )
}
