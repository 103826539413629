import React from 'react'
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";

export default function HeroSection({handleClick,handleSelectCiteria,selectedCiteria}) {
  return (
    <div className='herosection'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-md-10 offset-lg-2 offset-md-1'>
                    <div className='theme-card w-100'>
                        <h2>Welcher Gebäudetyp ist es?</h2>
                        <p>Wählen Sie Ihren Gebäudetyp</p>
                        <div className='box-outer hero-box'>
                            <div className={selectedCiteria === 'house'? 'theme-box active' : 'theme-box'} onClick={()=>handleSelectCiteria('house')}>
                                <div className='icon-box'>
                                    <img src='/home.svg' />
                                </div>
                                <h5>Einfamilienhaus</h5>
                            </div>
                            <div className={selectedCiteria === 'Apartment'? 'theme-box active' : 'theme-box'} onClick={()=>handleSelectCiteria('Apartment')}>
                                <div className='icon-box'>
                                    <img src='/apartment.svg' />
                                </div>
                                <h5>Mehrfamilienhaus</h5>
                            </div>
                            <div className={selectedCiteria === 'Commerce'? 'theme-box active' : 'theme-box'} onClick={()=>handleSelectCiteria('Commerce')}>
                                <div className='icon-box'>
                                    <img src='/building.svg' />
                                </div>
                                <h5>Geschäft</h5>
                            </div>
                            <div className={selectedCiteria === 'Office'? 'theme-box active' : 'theme-box'} onClick={()=>handleSelectCiteria('Office')}>
                                <div className='icon-box'>
                                    <img src='/office.svg' />
                                </div>
                                <h5>Bürogebäude</h5>
                            </div>
                        </div>
                        <div className='grp-btns'>
                            <div className='btn theme-btn alt'><GrLinkPrevious /> Zurück </div>
                            <div className={selectedCiteria === null? 'btn theme-btn disabled':'btn theme-btn'} onClick={()=>handleClick('people-count')}>Weiter <GrLinkNext/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
